import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import { InfoContext } from "../infoContext";
import { t } from "../i18n";
import { StateContext } from "../stateContext";
import Input from "./Input";
import Button from "./Button";
import Modal from "./Modal";
import { findClientByAreaIndex } from "../lib/utils";
import { getLogoUrl, update } from "../lib/api";

const Configure = () => {
  const { action } = useParams();
  
  const { state: { clients }, dispatch } = useContext(InfoContext);
  const { state: { planet, areaIndex } } = useContext(StateContext);
  const navigate = useNavigate();

  const client = findClientByAreaIndex(clients, planet, areaIndex);

  const [loading, setLoading] = useState(false);
  const nameState = useState(client?.name || 'No name');
  const descriptionState = useState(client?.description || 'No description');
  const linkState = useState(client?.link || 'https://');
  const logoState = useState(client?.logo ? (getLogoUrl(planet, areaIndex + 1, client.logo)) : '');

  useEffect(() => {
    nameState[1](client?.name || 'No name');
    descriptionState[1](client?.description || 'No description');
    linkState[1](client?.link || 'https://');
    logoState[1](client?.logo ? (getLogoUrl(planet, areaIndex + 1, client.logo)) : '');
  }, [planet, areaIndex]);

  if (action !== 'configure') {
    return null;
  }

  const handleSave = async () => {
    const props = {
      name: nameState[0],
      description: descriptionState[0],
      link: linkState[0],
      logo: logoState[0],
    };
    setLoading(true);
    const result = await update(planet, areaIndex, props);
    const url = '/' + planet + '/' + (areaIndex + 1);

    // if logo updated, reload page
    if (String(logoState[0]).indexOf(';base64,') > -1) {
      window.location.href = url;
    } else {
      dispatch({
        type: 'updateArea',
        payload: { planet, areaIndex, props: result },
      });
      setLoading(false);
      navigate(url);
    }
  }

  return (
    <Modal onClose={() => navigate('/' + planet + '/' + (areaIndex + 1))}>
      <div className="text-lg mb-2">{t('configure_parcel_title', { num: areaIndex + 1 })}</div>
      <Input label={t('field_name')} state={nameState} />
      <Input label={t('field_description')} state={descriptionState} />
      <Input label={t('field_link')} state={linkState} />
      <Input label={t('field_logo')} state={logoState} type="image" />
      <Button label={t('button_save')} onClick={handleSave} loading={loading} />
      <Button label={t('button_cancel')} variant="secondary" onClick={() => navigate('/' + planet + '/' + (areaIndex + 1))} />
    </Modal>
  );
};

export default Configure;
