import { createContext, useEffect, useReducer, useRef } from "react";

export const UserContext = createContext();

function userReducer(state, action) {
  switch (action.type) {
    case 'setUser': {
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    }
    case 'setLoading': {
      return {
        ...state,
        loading: action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function UserProvider({ children }) {
  const [state, dispatch] = useReducer(userReducer, {
    loading: true,
  });
  const prevStateRef = useRef({});

  useEffect(() => {
    prevStateRef.current = {
      ...state,
    };
  }, [state]);

  const value = { state, dispatch, prevState: prevStateRef.current }
  return (
    <UserContext.Provider value={value}>{children}</UserContext.Provider>
  )
}

export default UserProvider;
