import { generateThumbnail } from "./draw";

const baseUrl = 'https://api.nebulaft.com';

export const loadInfo = () => {
  return fetch(`${baseUrl}/info`).then((res) => {
    return res.json();
  }).then((res) => {
    return res;
  });
};

export const getClient = (planetId, areaIndex) => {
  return fetch(`${baseUrl}/info/${planetId}/${areaIndex}`).then((res) => {
    return res.json();
  }).then((res) => {
    return res;
  });
};

export const getTransactionStatus = (hash) => {
  return fetch(`${baseUrl}/transaction/${hash}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('spaceToken')}`,
    },
  }).then((res) => {
    return res.json();
  }).then((res) => {
    return res;
  });
};

export const checkout = async (planetId, areaId, area) => {
  const thumbnail = await generateThumbnail(planetId, area);

  const res = await fetch(`${baseUrl}/payment`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('spaceToken')}`,
    },
    body: JSON.stringify({ planetId, areaId, thumbnail })
  });
  
  return res.json();
};

export const update = (planetId, areaId, props) => {
  return fetch(`${baseUrl}/update`, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('spaceToken')}`,
    },
    body: JSON.stringify({ planetId, areaId, props })
  }).then((res) => {
    return res.json();
  }).then((res) => {
    return res;
  });
};

export const transfer = (props) => {
  return fetch(`${baseUrl}/transfer`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('spaceToken')}`,
    },
    body: JSON.stringify(props)
  }).then((res) => {
    return res.json();
  }).then((res) => {
    return res;
  });
};

export const login = (accessToken) => {
  return fetch(`${baseUrl}/login`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    },
  }).then((res) => {
    return res.json();
  }).then((res) => {
    localStorage.setItem('spaceToken', res.spaceToken)
    return res;
  });
};

export const getLogoUrl = (planet, area, cacheControl) => {
  if (!cacheControl) {
    return '/noimage.png';
  }
  let url = `${baseUrl}/image/${planet}/${area}`;
  /*
  if (cacheControl && !isNaN(cacheControl)) {
    url = url + '?cache=' + cacheControl;
  }*/
  return url;
}

export const getThumbnailUrl = (planet, area) => {
  return `${baseUrl}/image/${planet}/${area}/?type=thumbnail`;
}

export const getCeritificateUrl = (planet, area) => {
  return `${baseUrl}/certificate/${planet}/${area}/`;
}