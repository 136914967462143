import { LocalStorageCache, useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { InfoContext } from "../infoContext";
import { UserContext } from "../userContext";
import { t } from "../i18n";
import { getLogoUrl, login } from "../lib/api";
import { decodeSpaceToken } from "../lib/utils";

const User = () => {
  const [open, setOpen] = useState(false);
  const { user, logout, loginWithRedirect, isLoading, getAccessTokenSilently, error } = useAuth0();
  const { state: { clients } } = useContext(InfoContext);
  const { state: { user: theUser, loading }, dispatch } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      (async() => {
        const accessToken = await getAccessTokenSilently();
        const { spaceToken } = await login(accessToken);
        const decoded = decodeSpaceToken(spaceToken);
        dispatch({ type: 'setUser', payload: decoded });
      })();
    }
  }, [user, getAccessTokenSilently, dispatch]);

  useEffect(() => {
    if (!user && !isLoading) {
      const spaceToken = localStorage.getItem('spaceToken');
      if (localStorage.getItem('spaceToken')) {
        const decoded = decodeSpaceToken(spaceToken);
        if (decoded) {
          dispatch({ type: 'setUser', payload: decoded });
        } else {   
          dispatch({ type: 'setLoading', payload: false });
        }
      } else {
        dispatch({ type: 'setLoading', payload: false });
      }
    }
  }, [user, isLoading, dispatch]);

  if (error) {
    console.log('Errorerror', error);
  }

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
    localStorage.clear('spaceToken');
  };

  if (isLoading || loading) {
    return null;
  }

  const filteredItems = theUser ? clients.filter(c => c.customerId === theUser.id) : [];
  
  return (
    <div className="fixed top-4 right-4">
      {theUser ? (
        <>
          <div className="flex items-center">
            <span className="mr-2 sm:hidden">
              {theUser?.name}
            </span>
            <div className="avatar-container w-10 h-10 cursor-pointer" onClick={() => setOpen(!open)}>
              <img src={theUser.metadata.picture} alt="" className="border border-white border-solid rounded-full border-2 hover:border-violet-400" />
            </div>
          </div>
          <div className={"box p-2 mb-2 transition transition-all ease-in-out duration-300 absolute right-0 bg-black/50 " + (!open ? ' -top-96' : 'top-12')}>
            <div className="mb-2">
              {t('my_parcels')}
            </div>
            <div className="overflow-y-auto	max-h-52">
              {!filteredItems.length ? (
                <div>{t('no_parcels')}</div>
              ) : null}
              {filteredItems.map(c => (
                <div key={`${c.id}${c.planet}${c.area}`} onClick={() => navigate('/' + c.planet + '/' + (c.area + 1))} className="mb-2 flex items-center cursor-pointer hover:text-violet-400">
                  <img alt={`${c.name} logo`} src={getLogoUrl(c.planet, c.area + 1, c.logo)} className="mr-2" style={{ maxHeight: 16, maxWidth: 16 }} />
                  <div className="whitespace-nowrap pr-2">
                    {t(c.planet)} {t('parcel_number', { num: c.area + 1 })}
                  </div>
                </div>
              ))}
            </div>
            <hr className="mt-3 mb-1 box opacity-70" />
            <button className="hover:text-violet-400" onClick={handleLogout}>
              {t('logout')}
            </button>
          </div>
        </>
      ) : (
        <button className="hover:text-violet-400" onClick={() => loginWithRedirect({ appState: { returnTo: window.location.pathname } })}>
          {t('login')}
        </button>
      )}
    </div>
  );
};

export default User;
