import moment from 'moment';

export const randomRgbColor = () => {
  let r = Math.floor(Math.random() * 256); // Random between 0-255
  let g = Math.floor(Math.random() * 256); // Random between 0-255
  let b = Math.floor(Math.random() * 256); // Random between 0-255
  return 'rgb(' + r + ',' + g + ',' + b + ')';
};

export const getPlanetIdsInOrder = () => (['mercury', 'venus', 'moon', 'mars', 'jupiter', 'saturn', 'uranus', 'neptune']);

export function easeInOutQuad(x) {
  return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
}

export function findClientByAreaIndex(clients, planetId, areaIndex) {
  return clients.find((c) => c.area === areaIndex && c.planet === planetId);
}

export function findPlanetById(planets, planetId) {
  return planets.find((p) => p.id === planetId);
}

export function findRandomClientByPlanet(clients, planetId) {
  const filtered = clients.filter((c) => c.planet === planetId);
  return filtered[Math.floor(Math.random() * filtered.length)];
}

export const formatDate = (dateValue) => {
  return moment.utc(dateValue).format('L');
}

export const formatArea = (area, planetArea) => {
  const percentage = area / 12.465688059190324;
  return Math.round(planetArea * percentage * 1000000).toLocaleString() + ' km²';
}

export const formatPrice = (area, planetPrice) => {
  const percentage = area / 12.465688059190324;
  const price = planetPrice * percentage;
  return '$' + (Math.floor(price * 100) / 100).toLocaleString();
}

export function randomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function executeWhen(conditionFn, callbackFn) {
  if (conditionFn()) {
    callbackFn();
  } else 
  setTimeout(() => {
    executeWhen(conditionFn, callbackFn);
  }, 100);
}

export function decodeSpaceToken (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}