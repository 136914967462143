import { useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { StateContext } from '../stateContext';
import { t } from '../i18n';
import { getPlanetIdsInOrder } from '../lib/utils';
import Modal from './Modal';

const PlanetSelector = () => {
  const { state } = useContext(StateContext);
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);

  const planets = getPlanetIdsInOrder();
  const index = planets.indexOf(state.planet);
  const nextPlanet = index === planets.length - 1 ? planets[0] : planets[index + 1];
  const prevPlanet = index === 0 ? planets[planets.length - 1] : planets[index - 1];

  const data = {
    name: t(state.planet),
    diameter: t(`${state.planet}_info_diameter`),
    area: t(`${state.planet}_info_area`),
    distance: t(`${state.planet}_info_distance`),
    probability: t(`${state.planet}_info_probability`),
  };

  const handleMenuItemClick = (p) => {
    navigate('/' + p);
    setMenu(false);
  };

  return (
    <div id="selectorPanel" className="flex flex-col mr-2 pointer-events-auto sm:mr-0 sm:w-full">
      {menu && (
        <Modal onClose={() => setMenu(false)}>
          {planets.map(p => (
            <div key={p} className="text-center cursor-pointer p-2" onClick={() => handleMenuItemClick(p)}>
              {t(p)}
            </div>
          ))}
        </Modal>
      )}
      <div className="flex box flex-col p-2 mb-2 bg-black/50">
        <div className="flex flex-row">
          <div className="p-2 cursor-pointer text-lg hover:text-violet-400" onClick={() => {
            if (!state.animating) {
              navigate("/" + prevPlanet);
            }
          }}>
            &lt;
          </div>
          <div className="p-2 flex-1 text-center text-lg cursor-pointer" onClick={() => setMenu(true)}>
            {t(state.planet)}
          </div>
          <div className="p-2 cursor-pointer text-lg hover:text-violet-400" onClick={() => {
            if (!state.animating) {
              navigate("/" + nextPlanet);
            }
          }}>
            &gt;
          </div>
        </div>
      </div>
      <div className={`flex box flex-col p-2 bg-black/50 ${state.areaIndex > -1 ? 'sm:hidden' : ''}`}>
        <div>
          {Object.keys(data).map((key) => (
            <div key={key} className="flex">
              <div className="text-left flex-1 text-sm pr-2">{t('planet_info_' + key)}</div>
              <div className="text-sm">{typeof data[key] === 'function' ? data[key]() : data[key]}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PlanetSelector;
