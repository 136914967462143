const Modal = ({ onClose, ...props }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full z-10">
      <div className="absolute w-full h-full bg-black/50" onClick={onClose}></div>
      <div className="relative box top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-4 bg-black/80 w-1/2 sm:w-5/6" {...props} style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 100px)' }} />
    </div>
  );
};

export default Modal;
