import classnames from 'classnames';

const Button = ({ label, children, variant = 'primary', loading, ...props }) => {
  const otherClassNames = {
    primary: 'from-violet-400 bg-gradient-to-b to-violet-700 border-violet-400',
    secondary: 'border border-violet-400/50',
  };
  return (
    <button
      {...props}
      className={classnames('border border-violet-600/50 rounded-md p-2 mt-2 mr-2 pointer-events-auto cursor-pointer', otherClassNames[variant], props.className)}
      disabled={props.disabled || loading}
    >
      {loading ? 'Loading...' : (label || children)}
    </button>
  );
};

export default Button;
