import { Auth0Provider } from '@auth0/auth0-react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import StateProvider from './stateContext';
import './App.css';

import Canvas from './components/Canvas';
import Bar from './components/Bar';
import InfoProvider from './infoContext';
import User from './components/User';
import UserProvider from './userContext';
import Configure from './components/Configure';
import Transfer from './components/Transfer';
import Logo from './components/Logo';
import Details from './components/Details';

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

const router = createBrowserRouter([
  {
    path: "/:planet?/:areaNum?/:action?",
    element: (
      <Auth0ProviderWithRedirectCallback
        domain="dev-0hd5-qaz.auth0.com"
        clientId="tLnwL1f8km01D7ccxbTXjJ8xGNm2l9A3"
        authorizationParams={{
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          redirect_uri: window.location.origin
        }}
      >
        <InfoProvider>
          <StateProvider>
            <UserProvider>
              <div className="App">
                <Canvas />
                <Logo />
                <Bar />
                <User />
                <Configure />
                <Transfer />
                <Details />
              </div>
            </UserProvider>
          </StateProvider>
        </InfoProvider>
      </Auth0ProviderWithRedirectCallback>
    ),
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
