import { useContext, useEffect, useState } from "react";
import { getClient } from "../lib/api";
import { InfoContext } from "../infoContext";
import { findClientByAreaIndex } from "../lib/utils";

const useCustomerTransactionUpdated = (planetId, areaIndex, fn) => {
  const [loading, setLoading] = useState(true);
  const { state: { clients }, dispatch } = useContext(InfoContext);

  const client = findClientByAreaIndex(clients, planetId, areaIndex) || {};

  useEffect(() => {
    if (!client.planet) {
      setLoading(false);
      return;
    }

    const load = () => {
      getClient(client.planet, client.area).then((res) => {
        dispatch({
          type: 'updateArea',
          payload: { planet: client.planet, areaIndex: client.area, props: res },
        });
        if (!fn(res)) {
          clearInterval(interval);
        }  
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
    }
  
    load();
    const interval = setInterval(load, 10000);
    
    return () => {
      clearInterval(interval);
    }
  }, [client.planet, client.area, client.hash, client.tokenId, client.transferHash, client.owned, dispatch, fn]);

  return {
    loading,
  };
};

export default useCustomerTransactionUpdated;
