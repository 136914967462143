import { useContext } from 'react';
import { StateContext } from '../stateContext';
import { t } from '../i18n';

const PlanetInfo = () => {
  const { state } = useContext(StateContext);
  return (
    <div id="infoPanel" className="box mr-2 p-2 flex-1 overflow-y-auto flex-1 pointer-events-auto sm:hidden bg-black/50" style={{ maxHeight: 300 }}>
      <div className="mb-2">
        {t('why_to_buy')}
      </div>
      <div className="mb-2 mt-2 text-sm">
        {t(`${state.planet}_info_description`)}
      </div>
      <div className="mb-2 text-sm">
        {t(`${state.planet}_info_why_to_buy`)}
      </div>
      {/* <div className="mb-2">
        {t(`other_reasons`)}
      </div> */}
    </div>
  );
};

export default PlanetInfo;
