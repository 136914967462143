import { useContext, useEffect, useRef } from 'react';
import * as THREE from 'three';
import { useNavigate, useParams } from 'react-router-dom';

import { init, createPlanet, renderClient, drawStars, renderHighlight, generateThumbnail } from '../lib/draw';
// import { debug } from '../lib/debug';
import { StateContext } from '../stateContext';
import { easeInOutQuad, executeWhen, findRandomClientByPlanet, getPlanetIdsInOrder, randomInt } from '../lib/utils';
import { InfoContext } from '../infoContext';

window.calculate = null;

const MOVING_DISTANCE = 10;
const ANIMATION_LENGTH = 500;

const Canvas = () => {
  const navigate = useNavigate();
  const { planet: planetUrlValue, areaNum: areaIndexValue } = useParams();

  const ref = useRef(null);
  const contextRef = useRef();
  const { state, prevState, dispatch } = useContext(StateContext);
  const { state: { common, clients } } = useContext(InfoContext);

  useEffect(() => {
    if (state.areaIndex > -1 && !state.animating) {
      executeWhen(() => contextRef.current?.planetContext?.logos, () => {
        const { areas } = contextRef.current.planetContext;
        const center = areas[state.areaIndex].center;
        contextRef.current.controls.lookInDirectionOf(-center.x, -center.y, -center.z, true);
      })
    }
  }, [state.areaIndex, state.animating]);

  useEffect(() => {
    contextRef.current = init(ref.current);
    contextRef.current.animateListeners = [];
    drawStars(contextRef.current);

    const { camera, controls, renderer, pointer, scene, clock } = contextRef.current;
    
    controls.update();

    function animate(now) {
      const delta = clock.getDelta();

      requestAnimationFrame(animate);

      if (contextRef.current) {
        contextRef.current.animateListeners.forEach(listener => {
          listener(now);
        });
      }


      // renderer.render( scene, camera );

      controls.update(delta);

      renderer.render(scene, camera);
    }
    requestAnimationFrame(animate);
  }, []);

  useEffect(() => {
    const planetsInOrder = getPlanetIdsInOrder();
    const side = planetsInOrder.indexOf(state.planet) > planetsInOrder.indexOf(prevState.planet) ? 1 : -1;

    const leave = async () => {
      const context = contextRef.current;
      if (context.planetContext && context.planetContext.planet) {
        const { planetGroup } = context.planetContext;
        context.controls.enabled = false;

        await new Promise(resolve => {
          const startTime = new Date().valueOf();
          const listener = () => {
            const currentTime = new Date().valueOf();
            const diff = currentTime - startTime;
            const value = easeInOutQuad(diff / ANIMATION_LENGTH) * MOVING_DISTANCE * (-side);

            const target = new THREE.Vector3(value, 0, 0);
            if (diff < ANIMATION_LENGTH) {
              planetGroup.position.copy(target);
            } else {
              planetGroup.position.copy(new THREE.Vector3(MOVING_DISTANCE * (-side), 0, 0));
              resolve();
              contextRef.current.animateListeners.splice(contextRef.current.animateListeners.indexOf(listener), 1);
            }
          };
          contextRef.current.animateListeners.push(listener);
        });

        // then remove planet
        context.scene.remove(planetGroup);
      }

      return true;
    };    

    const start = async () => {
      const context = contextRef.current;
      const { scene, controls } = context;
      const { planet, uv, rings } = await createPlanet(context, state.planet);
      const { geometry } = planet;
      const position = geometry.getAttribute('position');
      const areas = common;

      const planetGroup = new THREE.Group();
      planetGroup.add(planet);
      if (rings) {
        planetGroup.add(rings);
      }

      const planetContext = { planet, geometry, position, uv, areas, planetGroup };
      context.planetContext = planetContext;

      // debug(context, planet);

      const flags = [];

      context.controls.addEventListener('change', () => {
        const scaleVector = new THREE.Vector3();
        const scaleFactor = 4;
        for (let flag of flags) {
          const scale = scaleVector.subVectors(flag.position, context.camera.position).length() / scaleFactor;
          flag.scale.set(scale, scale, scale);
        }
      });

      const initialPosition = new THREE.Vector3(MOVING_DISTANCE * side, 0, 0);
      planetGroup.position.copy(initialPosition);
      scene.add(planetGroup);

      const randomClient = findRandomClientByPlanet(clients, state.planet);
      const areaIndex = randomClient ? randomClient.area : randomInt(0, areas.length - 1);
      const initialClientCenter = areas[areaIndex].center;
      controls.lookInDirectionOf(-initialClientCenter.x, -initialClientCenter.y, -initialClientCenter.z, true);

      const startTime = new Date().valueOf();

      await new Promise((resolve) => {
        const listener = () => {
          const currentTime = new Date().valueOf();
          const diff = currentTime - startTime;
          const value = MOVING_DISTANCE * side - easeInOutQuad(diff / ANIMATION_LENGTH) * MOVING_DISTANCE * side;

          const target = new THREE.Vector3(value, 0, 0);
          if (diff < ANIMATION_LENGTH) {
            planetGroup.position.copy(target);
          } else {
            planetGroup.position.copy(new THREE.Vector3(0, 0, 0));
            contextRef.current.animateListeners.splice(contextRef.current.animateListeners.indexOf(listener), 1);
            context.controls.enabled = true;

            const logos = clients.filter(client => client.planet === state.planet).map((client) => {
              return renderClient(context, planetContext, client);
            });

            planetContext.logos = logos;

            resolve();
          }
        };
        contextRef.current.animateListeners.push(listener);
      });
    };

    dispatch({ type: 'animating', payload: true });
    leave().then(() => {
      return start();
    }).then(() => {
      dispatch({ type: 'animating', payload: false });
    });

    let downX = 0;
    let downY = 0;

    const downListener = (event) => {
      downX = event.clientX;
      downY = event.clientY;
    }

    const upListener = (event) => {
      if (Math.abs(event.clientX - downX) > 5 || Math.abs(event.clientY - downY) > 5) {
        return;
      }
      if (event.target !== document.querySelector('canvas')) {
        return;
      }
      const { camera, planetContext: { areas, planet } } = contextRef.current;

      const raycaster = new THREE.Raycaster();
      const mouse = new THREE.Vector2();
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObject(planet, true);

      if (intersects.length) {
        const face = intersects[0].face;
        const areaIndex = areas.findIndex((a) => {
          return a.triangles.some((t) => {
            return t[0] === face.a && t[1] === face.b && t[2] === face.c;
          })
        });
        
        navigate(`/${state.planet}/${areaIndex + 1}`);
      } else {
        console.log('aaaaaa delesect')
        navigate(`/${state.planet}`);
      }
    };
    document.addEventListener('click', upListener, false);
    document.addEventListener('mousedown', downListener, false);

    return () => {
      document.removeEventListener('click', upListener);
      document.removeEventListener('mousedown', downListener);
    };
  }, [state.planet]);

  // when area or planet change, change the selection
  useEffect(() => {
    const { scene } = contextRef.current;
    let client;
    let selection;

    if (contextRef.current.planetContext && areaIndexValue !== undefined) {
      const { areas, logos } = contextRef.current.planetContext;
      if (logos) {
        client = logos.find((l => l.client.area === areaIndexValue - 1));
        if (client) {
          client.logo.material.opacity = 1;
        } else {
          selection = renderHighlight(contextRef.current, contextRef.current.planetContext, areas[areaIndexValue - 1]);
        }
      }  
    }
    
    return () => {
      if (client) {
        client.logo.material.opacity = 0.8;
      }
      if (selection) {
        scene.remove(selection);
      }
    };
  }, [planetUrlValue, areaIndexValue, contextRef.current?.planetContext]);

  return <div ref={ref}></div>;
};

export default Canvas;