import { useCallback, useContext, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';

import { StateContext } from '../stateContext';
import { t } from '../i18n';
import { findClientByAreaIndex, findPlanetById, formatArea, formatDate, formatPrice } from '../lib/utils';
import { InfoContext } from '../infoContext';
import { checkout, getLogoUrl } from '../lib/api';
import { UserContext } from '../userContext';
import Button from './Button';
import useCustomerTransactionUpdated from '../hooks/useCustomerTransactionUpdated';

const getTransferStatus = (client) => {
  if (client.owned) {
    return 'owned';
  }
  if (client.transferError) {
    return 'transferringError';
  }
  if (client.transferHash) {
    return 'transferring';
  }
  if (client.tokenId) {
    return 'ready';
  }
  return 'pending';
}

const getLink = (client) => {
  if (!client.tokenId) {
    return "https://etherscan.io/tx/" + client.hash;
  } else {
    return "https://etherscan.io/token/0x6f882cf103713495c78ad6a0b6d108a14585ec28?a=" + client.tokenId;
  }
}

const getOpenSeaLink = (client) => {
  if (!client.tokenId) {
    return null;
  } else {
    return "https://opensea.io/assets/ethereum/0x6f882cf103713495c78ad6a0b6d108a14585ec28/" + client.tokenId;
  }
}

const SelectionPanel = () => {
  const [checkingOut, setCheckingOut] = useState(false);
  const { loginWithRedirect } = useAuth0();
  const { state: { user } } = useContext(UserContext);
  const navigate = useNavigate();
  
  const { state } = useContext(StateContext);
  const { state: { common, clients, planets } } = useContext(InfoContext);
  const client = findClientByAreaIndex(clients, state.planet, state.areaIndex);
  const planet = findPlanetById(planets, state.planet);
  const areas = common;
  const area = areas[state.areaIndex];

  const check = useCallback((res) => {
    return (res.hash && !res.tokenId) || (res.transferHash && !res.owned);
  }, []);
  const { loading } = useCustomerTransactionUpdated(state.planet, state.areaIndex, check);

  const data = client ? {
    owner: client.name || 'No name',
    area: formatArea(area.h, planet.area),
    purchaseDate: formatDate(client.purchaseDate),
    link: () => client.link ? <a className="hover:text-violet-400" href={client.link} target="_blank" rel="noreferrer">{t('visit_link')}</a> : 'No link',
    nft: () => <a className="hover:text-violet-400" href={getLink(client)} target="_blank" rel="noreferrer">{t('view_nft')}</a>,
    openSeaNft: client.tokenId ? () => <a className="hover:text-violet-400" href={getOpenSeaLink(client)} target="_blank" rel="noreferrer">{t('view_opensea_nft')}</a> : null,
    nftStatus: loading ? '...' : t('nft_status_' + getTransferStatus(client)),
  } : {};

  const handlePurchase = () => {
    if (user?.id) {
      setCheckingOut(true)
      checkout(state.planet, state.areaIndex, area).then(({ url }) => {
        window.location.href = url;
        setCheckingOut(false)
      }).catch(e => {
        setCheckingOut(false);
      });
    } else {
      loginWithRedirect({ appState: { returnTo: window.location.pathname } });
    }
  };
  
  if (state.areaIndex === -1) {
    return null;
  }

  const isOwner = client && client.customerId === user?.id;

  return (
    <div id="selectionPanel" className="selection-panel box flex flex-col mr-2 relative pointer-events-auto sm:w-full sm:mr-0">
      {state.areaIndex > -1 && !client && (
        <div className="ribbon"><span>{t('for_sale_ribbon')}</span></div>
      )}

      <div className="p-2 w-full text-center">
        {state.areaIndex === -1 ? (
          t('select_parcel')
        ) : (
          t('parcel_number', { num: state.areaIndex + 1 })
        )}
      </div>
      {state.areaIndex > -1 && (
        <div className="p-2 w-full text-center sm:flex sm:flex-wrap">
          {client ? (
            <div className="sm:mr-2 basis-1/2">
              <img alt="No logo" src={getLogoUrl(client.planet, state.areaIndex + 1, client.logo)} className="mb-2 mx-auto" style={{ maxHeight: '16vh' }} />
              {client.description ? (
                <div className="mb-2">
                  {client.description}
                </div>
              ) : ''}
            </div>
          ) : null}
          <div className="sm:flex-1">
            {Object.keys(data).map((key) => (
              <div key={key} className="flex">
                <div className="text-left flex-1 text-sm sm:hidden mr-4">{t('property_' + key)}</div>
                <div className="text-sm">{typeof data[key] === 'function' ? data[key]() : data[key]}</div>
              </div>
            ))}
          </div>
          <div className="sm:flex-1 sm:basis-full flex flex-col">
            {isOwner && (
              <>
                <Button className="w-full" onClick={() => navigate('/' + state.planet + '/' + (state.areaIndex + 1) + '/configure')}>
                  {t('configure_this_parcel')}
                </Button>
                <Button className="w-full" onClick={() => navigate('/' + state.planet + '/' + (state.areaIndex + 1) + '/details')}>
                  {t('view_my_documents')}
                </Button>
                {!isNaN(client.tokenId) && !client.transferHash && (
                  <Button className="w-full" onClick={() => navigate('/' + state.planet + '/' + (state.areaIndex + 1) + '/transfer')}>
                    {t('transfer_my_nft')}
                  </Button>
                )}
              </>
            )}
          </div>
          {!client && (
            <div>
              <div className="mb-2">
                {t('for_sale', { num: state.areaIndex + 1, area: formatArea(area.h, planet.area) })}
              </div>
              <Button onClick={handlePurchase} disabled={checkingOut}>
                {t('buy_button', {
                  num: state.areaIndex + 1,
                  planet: t(state.planet),
                })}
                <div className="text-xl">
                  {formatPrice(area.h, planet.price)}
                  {checkingOut && '...'}
                </div>
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectionPanel;
