import classnames from 'classnames';
import { useState } from 'react';

const Tab = ({ tabs, ...props }) => {
  const [index, setIndex] = useState(0);

  const handleClick = (newIndex) => {
    setIndex(newIndex);
  };

  return (
    <div
      {...props}
    >
      <div className="flex flex-row mb-2">
        {tabs.map((tab, i) => (
          <div className={classnames('flex items-center text-center border border-violet-600 rounded-md p-2 mr-2 flex-1 cursor-pointer', i !== index && 'border-violet-600/50')} onClick={() => handleClick(i)}>
            <span>
              {tab.label}
            </span>
          </div>
        ))}
      </div>
      <div className={classnames('border border-violet-600/50 rounded-md p-2 mb-2')}>
        {tabs[index].content}
      </div>
    </div>
  );
};

export default Tab;
