import { useCallback, useContext, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import { InfoContext } from "../infoContext";
import { t } from "../i18n";
import { StateContext } from "../stateContext";
import Input from "./Input";
import Button from "./Button";
import Modal from "./Modal";
import { findPlanetById } from "../lib/utils";
import { transfer } from "../lib/api";

const Transfer = () => {
  const { action } = useParams();
  
  const { state: { planets }, dispatch } = useContext(InfoContext);
  const { state: { planet, areaIndex } } = useContext(StateContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const addressState = useState('');
  const planetObj = findPlanetById(planets, planet);

  if (action !== 'transfer') {
    return null;
  }

  const handleTransfer = async () => {
    setLoading(true);

    try {
      const hash = await transfer({
        address: addressState[0],
        planetId: planet,
        areaId: areaIndex,
      });
      console.log('aaaa', hash);
      dispatch({
        type: 'updateArea',
        payload: { planet, areaIndex, props: {
          transferHash: hash,
        } },
      });

      setLoading(false);  
    } catch (e) {
      console.log('Error transfering', e);
      setLoading(false);  
    }
    
    const url = '/' + planet + '/' + (areaIndex + 1);
    navigate(url);
  }

  return (
    <Modal onClose={() => navigate('/' + planet + '/' + (areaIndex + 1))}>
      <div className="text-lg mb-2">{t('transfer_title')}</div>
      <div className="mb-2">{t('transfer_description', { planet: planetObj.name, num: areaIndex + 1 })}</div>
      <Input placeholder="0x..." label={t('field_address')} state={addressState} />
      <Button label={t('button_transfer')} onClick={handleTransfer} loading={loading} />
      <Button label={t('button_cancel')} variant="secondary" onClick={() => navigate('/' + planet + '/' + (areaIndex + 1))} />
    </Modal>
  );
};

export default Transfer;
