import PlanetInfo from './PlanetInfo';
import PlanetSelector from './PlanetSelector';
import SelectionPanel from './SelectionPanel';

const Bar = () => {
  return (
    <div className="fixed bottom-4 left-4 flex flex-row sm:flex-col items-end w-full pointer-events-none" style={{ maxWidth: 'calc(100vw - 2rem' }}>
      <PlanetSelector />
      <PlanetInfo />
      <SelectionPanel />
    </div>
  );
};

export default Bar;
