import { useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import { InfoContext } from "../infoContext";
import { t } from "../i18n";
import { StateContext } from "../stateContext";
import { findPlanetById } from "../lib/utils";
import { getLogoUrl, getThumbnailUrl, getCeritificateUrl } from "../lib/api";
import Tab from "./Tab";
import Image from "./Image";
import Modal from "./Modal";

const Details = () => {
  const { action } = useParams();
  const navigate = useNavigate();

  const { state: { planets } } = useContext(InfoContext);
  const { state: { planet, areaIndex } } = useContext(StateContext);
  
  const planetObj = findPlanetById(planets, planet);

  if (action !== 'details') {
    return null;
  }

  return (
    <Modal onClose={() => navigate('/' + planet + '/' + (areaIndex + 1))}>
      <div className="text-lg mb-2">{t('details_title', { planet: planetObj.name, num: areaIndex + 1 })}</div>

      <Tab tabs={[
        {
          label: t('image_certificate'),
          content: <Image src={getCeritificateUrl(planet, areaIndex)} alt={t('image_certificate')} />,
        },
        {
          label: t('image_area'),
          content: <Image src={getThumbnailUrl(planet, areaIndex + 1)} alt={t('image_area')} />,
        },
        {
          label: t('image_logo'),
          content: <Image src={getLogoUrl(planet, areaIndex + 1, true)} alt={t('image_logo')} />,
        },
      ]} />
    </Modal>
  );
};

export default Details;
