import { useEffect, useState } from 'react';

const Image = ({ placeholder, ...props }) => {
  const [loading, setLoading] = useState(true);
  const [emptySrc, setEmptySrc] = useState(false);

  useEffect(() => {
    setLoading(true);
    setEmptySrc(true);
  }, [props.src])

  useEffect(() => {
    setEmptySrc(false);
  }, [emptySrc])

  return (
    <>
      {loading ? (placeholder || 'Loading...') : null}
      <img {...props} onLoad={() => setLoading(false)} alt={props.alt} src={emptySrc ? '' : props.src} />
    </>
  );
};

export default Image;
