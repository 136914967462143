import { useEffect } from 'react';
import { useFilePicker } from 'use-file-picker';

const ImageInput = ({ value, setValue }) => {
  const { openFilePicker, filesContent, loading } = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
  });

  const file = filesContent.length ? filesContent[0] : (value ? { content: value } : null);

  useEffect(() => {
    if (file) {
      setValue(file.content);
    }
  }, [setValue, file])

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {file ? (
        <img className="max-h-56" alt="" src={file.content} onClick={() => openFilePicker()} style={{ minWidth: 10, minHeight: 10 }}></img>
      ) : (
        <button className="p-2 border border-white" onClick={() => openFilePicker()}>Select image</button>
      )}
    </div>
  );
}

const Input = ({ label, state: [value, setValue], type, ...props }) => {

  return (
    <div className="mb-2 flex flex-col">
      <label className="mb-1">{label}</label>
      {type === 'image' && (
        <ImageInput value={value} setValue={setValue} />
      )}
      {type !== 'image' && (
        <input
          {...props}
          type="text"
          className="text-black p-2 w-full"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      )}
    </div>
  );
};

export default Input;
