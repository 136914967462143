import { createContext, useEffect, useReducer, useRef, useState } from "react";
import classnames from "classnames";

import { loadInfo } from "./lib/api";

export const InfoContext = createContext();

function infoReducer(state, action) {
  switch (action.type) {
    case 'setInfo': {
      return {
        ...state,
        loading: false,
        planets: action.payload.planets,
        totalArea: action.payload.totalArea,
        common: action.payload.common,
        clients: action.payload.clients,
      };
    }
    case 'updateArea': {
      return {
        ...state,
        clients: state.clients.map((c) => {
          if (c.planet === action.payload.planet && c.area === action.payload.areaIndex) {
            return {
              ...c,
              ...action.payload.props,
            };
          }
          return c;
        }),
      };
    }
    case 'setLoading': {
      return {
        ...state,
        loading: action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const InfoLoader = ({ state, children }) => {
  const [animating, setAnimating] = useState(true);

  useEffect(() => {
    state.dispatch({
      type: 'setLoading',
      payload: true,
    });
    loadInfo().then((res) => {
      state.dispatch({
        type: 'setInfo',
        payload: res,
      });
    });
  }, []);

  useEffect(() => {
    if (!state.state.loading) {
      setTimeout(() => {
        setAnimating(false);
      }, 2000);
    }
  }, [state.state.loading])

  if (state.state.loading || animating) {
    return (
      <div className="bg-black fixed w-full h-full top-0 left-o flex items-center justify-center flex-col">
        <div className={classnames('flex flex-row mb-4', !state.state.loading && animating && 'logo-container-final')}>
          <img alt="" src="/logo-squared.png" style={{ height: 52 }} className={classnames('logo-n', !state.state.loading && animating && 'logo-n-final')} />
          <img alt="" src="/logo-rest.png" style={{ height: 52 }} className={classnames('logo-rest', !state.state.loading && animating && 'logo-rest-final')} />
        </div>
        <div className={classnames('text-white', !state.state.loading && animating && 'logo-loading-final')}>
          LOADING
        </div>
      </div>
    );
  }

  return children;
}

function InfoProvider({ children }) {
  const [state, dispatch] = useReducer(infoReducer, {
    loading: true,
  });
  const prevStateRef = useRef({});

  useEffect(() => {
    prevStateRef.current = {
      ...state,
    };
  }, [state]);

  const value = { state, dispatch, prevState: prevStateRef.current }
  return (
    <InfoContext.Provider value={value}>
      <InfoLoader state={value}>{children}</InfoLoader>
    </InfoContext.Provider>
  )
}

export default InfoProvider;
