import { createContext, useEffect, useReducer, useRef } from "react";
import { useParams, useLocation } from 'react-router-dom';

import { getPlanetIdsInOrder } from "./lib/utils";

export const StateContext = createContext();

function stateReducer(state, action) {
  switch (action.type) {
    case 'setPlanet': {
      return {
        ...state,
        planet: action.payload,
        areaIndex: -1,
      };
    }
    case 'setAreaIndex': {
      return {
        ...state,
        areaIndex: action.payload
      };
    }
    case 'prev': {
      const planets = getPlanetIdsInOrder();
      const index = planets.indexOf(state.planet);
      return {
        ...state,
        planet: index === 0 ? planets[planets.length - 1] : planets[index - 1],
      };
    }
    case 'next': {
      const planets = getPlanetIdsInOrder();
      const index = planets.indexOf(state.planet);
      return {
        ...state,
        planet: index === planets.length - 1 ? planets[0] : planets[index + 1],
      };
    }
    case 'animating': {
      return {
        ...state,
        animating: action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function StateProvider({ children }) {
  const { planet: planetUrlValue, areaNum: areaIndexValue } = useParams();
  const [state, dispatch] = useReducer(stateReducer, {
    planet: planetUrlValue || 'mars',
    animating: true,
    areaIndex: areaIndexValue ? Number(areaIndexValue) - 1 : -1,
  });
  const prevStateRef = useRef({});


  useEffect(() => {
    if (planetUrlValue) {
      dispatch({
        type: 'setPlanet',
        payload: planetUrlValue
      });
    }
  }, [planetUrlValue]);
  
  useEffect(() => {
    dispatch({
      type: 'setAreaIndex',
      payload: Number(areaIndexValue || 0) - 1,
    });
  }, [areaIndexValue]);

  useEffect(() => {
    prevStateRef.current = {
      ...state,
    };
  }, [state]);

  const value = { state, dispatch, prevState: prevStateRef.current }
  return <StateContext.Provider value={value}>{children}</StateContext.Provider>
}

export default StateProvider;
