import en from './data/en.json';

let lang = "en";

const translations = {
  en,
};

export const t = (key, values) => {
  let text = key;
  if (translations[lang][key]) {
    text = translations[lang][key];
  }
  Object.keys(values || {}).forEach((key) => {
    text = text.replace(`{${key}}`, values[key])
  });
  // todo replace values
  return text;
};
